import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";


type PriceProps = {
  amount: number;
  currency: string;
}

const Price = ({ amount, currency }: PriceProps) => {
  const formatPrice = () => {
    if (amount > 0 && !!currency) {
      try {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency }).format(amount);
      } catch (error) {
        console.error('Error formatting price:', error);
        return `${amount} ${currency}`;
      }
    }
    return 'n/a'
  }
    
  return <TypographyStyles.LabelXL color={colors.black}>{formatPrice()}</TypographyStyles.LabelXL>
}

export default Price;
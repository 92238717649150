import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../utils/colors'
import mixpanel from 'mixpanel-browser';

import ReliabilityScoreBadge from './ReliabilityScoreBadge';
import Price from './Price';
import TimeIndication from './TimeIndication';
import LinesInfo from './LinesInfo';
import Tags from './Tags';
import Statistics from './Statistics';
import StatisticsBox from '../StatisticsBox';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`
  
const JourneyCardBody = styled('div')`
  background-color: ${colors.white};
  border-bottom: 2px solid ${colors.grey400};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  cursor: pointer;
`

const Footer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StatisticsContainer = styled('div')`
  margin: 12px 0;
`

const LinesInfoWithMargin = styled.div<{ addMargin: boolean }>`
  margin-bottom: ${({ addMargin }) => (addMargin ? '12px' : '0')};
`;

type JourneyCardProps = {
  journey: any;
  onClick: () => void;
}

const JourneyCard = ({ journey, onClick }: JourneyCardProps) => {
  // Determine if there are any statistics to display
  const hasStatistics = () => {
    const delayStats = journey.journeyDelayStats;
    const transferStats = journey.transferStats;

    const isDelayStatsVisible = delayStats?.averageDelayAtDest !== null || delayStats?.journeyCancellationRate !== null;
    const isAnyTransferStatsVisible = transferStats?.some((stat: any) => {
      return (
        (stat.averageTransferTime !== null && stat.averageTransferTime !== undefined) ||
        (stat.percentageMissedConnections !== null && stat.percentageMissedConnections !== undefined)
      );
    });

    return isDelayStatsVisible || isAnyTransferStatsVisible;
  };

  const navigate = useNavigate();

  const handleJourneyClick = () => {
    // Prepare data for Mixpanel event
    const journeyLegs = journey.legs.map((leg: any) => leg.line.name);
    const journeyPrice = journey.price ? `${journey.price.amount} ${journey.price.currency}` : "N/A";

    // Send Mixpanel event
    mixpanel.track('Journey Clicked', {
      journey_id: journey.id,
      number_of_legs: journey.legs.length,
      price: journeyPrice,
      line_names: journeyLegs,
    });

    navigate(`/journey/${journey.id}`, { state: { from: window.location.pathname, journey } });  // Pass current location in state
  };

  const showStatistics = hasStatistics();

  return (
    <Container>
      {journey.tags && journey.tags.length > 0 && <Tags tags={journey.tags} />}
      <JourneyCardBody onClick={handleJourneyClick}>
        <TimeIndication legs={journey.legs} />
        <LinesInfoWithMargin addMargin={!showStatistics}>
          <LinesInfo legs={journey.legs} />
        </LinesInfoWithMargin>
        {showStatistics && (
          <StatisticsContainer>
            <StatisticsBox>
              <Statistics journey={journey} />
            </StatisticsBox>
          </StatisticsContainer>
        )}
        <Footer>
          {journey.reliability?.reliabilityScore !== undefined && (
            <ReliabilityScoreBadge             
              score={journey.reliability.reliabilityScore} 
              completeness={journey.reliability.reliabilityScoreCompleteness}  
            />
          )}
          {journey.price && journey.price.amount && (
            <Price amount={journey.price.amount} currency={journey.price.currency} />
          )}
        </Footer>
      </JourneyCardBody>
    </Container>
  );
};

export default JourneyCard;
